import axios from "axios";

const axiosBase = axios.create({
    headers: {
        "Content-Type": "application/json"
    }
});

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol/chamados`;

export default {
    solicitarConsultoriaExpress(data) {
        return axiosBase.post(`${api}/consultoriaExpress`, data);
    },
    solicitarCuradoria(data) {
        return axiosBase.post(`${api}/curadoria`, data);
    },
    solicitarProdutoReal(data) {
        return axiosBase.post(`${api}/solicitarProdutoReal`, data);
    }
};