<template>
    <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center" style="width: 70rem; margin-top: 2rem">
            <div style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, #0f97c7 10%, rgba(33, 150, 243, 0) 30%)">
                <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius: 53px">
                    <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">Solicitação de indicação de produto</h1>

                    <div class="flex align-items-center py-5 border-300 border-bottom-1" style="width: 99%">
                        <Message :closable="false"
                            >Esse formulário é destinado ao gestor que deseja solicitar indicação de produto, criação de cestas ou trilhas de produto, e games
                            no kahoot. Após o preenchimento, um gestor da UDS entrará em contato com você para dar início a curadoria e coletar mais
                            informações, caso necessário..</Message
                        >
                    </div>

                    <div class="w-full flex align-items-center py-5 border-300" v-if="erros.length > 0">
                        <Message class="w-full" v-for="erro in erros" :key="erro" severity="error">{{ erro }}</Message>
                    </div>

                    <div class="w-full flex align-items-center py-5 border-300">
                        <div class="p-fluid formgrid grid w-full">
                            <Fieldset class="mb-5 w-full">
                                <template #legend>
                                    <div class="flex align-items-center text-primary">
                                        <span class="pi pi-user mr-2"></span>
                                        <span class="font-bold text-lg">Identificação</span>
                                    </div>
                                </template>
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-6">
                                        <label for="nome">Nome (Solicitante)<i class="p-error">*</i></label>
                                        <InputText id="nome" v-model="nomeSolicitante" type="text" :class="{ 'p-invalid': v$.nomeSolicitante.$error }" />
                                        <small class="p-error" v-if="v$.nomeSolicitante.$error">Nome do solicitante é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="nome">E-mail (Solicitante)<i class="p-error">*</i></label>
                                        <InputText id="nome" v-model="emailSolicitante" type="text" :class="{ 'p-invalid': v$.emailSolicitante.$error }" />
                                        <small class="p-error" v-if="v$.emailSolicitante.$error">E-mail solicitante é obrigatório</small>
                                    </div>
                                </div>
                            </Fieldset>

                            <Fieldset class="mb-5 w-full" v-if="visualizarAbaCuradoria">
                                <template #legend>
                                    <div class="flex align-items-center text-primary">
                                        <span class="pi pi-shopping-cart mr-2"></span>
                                        <span class="font-bold text-lg">Produto</span>
                                    </div>
                                </template>
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-6">
                                        <label for="nome">Nome do gestor<i class="p-error">*</i></label>
                                        <InputText id="nome" v-model="nomeGestor" type="text" :class="{ 'p-invalid': v$.nomeGestor.$error }" />
                                        <small class="p-error" v-if="v$.nomeGestor.$error">Nome do Gestor é obrigatório(a)</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="nome">Qual a sua unidade?<i class="p-error">*</i></label>
                                        <InputText id="nome" v-model="unidade" type="text" :class="{ 'p-invalid': v$.unidade.$error }" />
                                        <small class="p-error" v-if="v$.unidade.$error">Unidade é obrigatória</small>
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="necessidade">Qual a sua necessidade?<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="necessidade"
                                            optionLabel="value"
                                            v-model="necessidade"
                                            filter
                                            :options="necessidades"
                                            :class="{ 'p-invalid': v$.necessidade.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.necessidade.$error">Necessidade é obrigatória</small>
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="temas">Quais são as temáticas necessárias?<i class="p-error">*</i></label>
                                        <InputText id="temas" v-model="temas" type="text" :class="{ 'p-invalid': v$.temas.$error }" />
                                        <small class="p-error" v-if="v$.temas.$error">Temática é obrigatória <br /></small>
                                        <small>Ex.: Atendimento ao cliente, marketing digital, formação de preço, comportamento empreendedor...</small>
                                    </div>

                                    <div class="field col-12 md:col-6">
                                        <label for="isntrumento">Preferência de instrumento do produto?<i class="p-error">*</i></label>
                                        <MultiSelect
                                            id="isntrumento"
                                            optionLabel="value"
                                            v-model="instrumentoProduto"
                                            filter
                                            :options="instrumentos"
                                            :class="{ 'p-invalid': v$.instrumentoProduto.$error }"
                                        ></MultiSelect>
                                        <small class="p-error" v-if="v$.instrumentoProduto.$error">Instrumento é obrigatório <br /> </small>
                                        <small>Pode selecionar mais de uma opção. Caso seja kahoot, selecione "não se aplica".</small>
                                    </div>

                                    <div class="field col-12 md:col-6">
                                        <label for="gratuito">O(s) produto(s) deve ser gratuito ou pago?<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="gratuito"
                                            optionLabel="value"
                                            v-model="tipoPagamento"
                                            filter
                                            :options="tiposDePagamento"
                                            :class="{ 'p-invalid': v$.necessidade.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.necessidade.$error">Tipo de Pagamento é obrigatório <br /> </small>
                                        <small>Caso seja kahoot, selecione gratuito.</small>
                                    </div>

                                    <div class="field col-12 md:col-6">
                                        <label for="publicoAlvo">Qual é o público alvo?<i class="p-error">*</i></label>
                                        <InputText id="publicoAlvo" v-model="publicoAlvo" type="text" :class="{ 'p-invalid': v$.publicoAlvo.$error }" />
                                        <small class="p-error" v-if="v$.publicoAlvo.$error">Público Alvo é obrigatório</small>
                                    </div>

                                    <div class="field col-12 md:col-6">
                                        <label for="prazo">Qual o seu prazo?<i class="p-error">*</i></label>
                                        <Calendar id="prazo" v-model="prazo" :class="{ 'p-invalid': v$.prazo.$error }" showIcon />
                                        <small class="p-error" v-if="v$.prazo.$error">Prazo é obrigatório</small>
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="isntrumento">No caso de indicação de produto, há preferência de formato?<i class="p-error">*</i></label>
                                        <MultiSelect
                                            id="isntrumento"
                                            optionLabel="value"
                                            v-model="tipoProduto"
                                            filter
                                            :options="tiposProdutos"
                                            :class="{ 'p-invalid': v$.tipoProduto.$error }"
                                        ></MultiSelect>
                                        <small class="p-error" v-if="v$.tipoProduto.$error">Indicação é obrigatória <br /> </small>
                                        <small>Pode selecionar mais de uma opção.</small>
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="descricaoObjetivo"
                                            >No caso de criação de cesta, nos conta um pouco mais! Quais são os objetivos e necessidades a serem atingidos com
                                            essa cesta? É um público novo ou que já participou de ações conosco?<i class="p-error">*</i></label
                                        >
                                        <Textarea
                                            id="descricaoObjetivo"
                                            v-model="descricaoObjetivo"
                                            :class="{ 'p-invalid': v$.descricaoObjetivo.$error }"
                                            rows="5"
                                        />
                                        <small class="p-error" v-if="v$.descricaoObjetivo.$error">Objetivo é obrigatório</small>
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="descricaoObjetivoKahoot"
                                            >Caso seja kahoot, conta aqui qual é o objetivo dessa ação! Vai ser utilizado em alguma feira? É para algum
                                            propósito específico?</label
                                        >
                                        <Textarea id="descricaoObjetivo" v-model="descricaoObjetivoKahoot" rows="5" />
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="informacaoAdicional">Deseja adicionar mais alguma informação?</label>
                                        <Textarea id="informacaoAdicional" v-model="informacaoAdicional" rows="5" />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>

                    <div class="w-full flex align-items-end justify-content-end" v-if="!visualizarAbaCuradoria">
                        <Button class="justify-content-end" label="Próximo" @click.stop="validarProximo()"></Button>
                    </div>
                    <div class="w-full flex align-items-center py-5 border-300 border-bottom-1" v-if="visualizarAbaCuradoria">
                        <Button class="w-full" label="Enviar" @click.stop="validarEnvio()"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="visualizarModal" modal :closable="false" :style="{ width: '50vw' }">
        <div class="card">
            <div class="text-3xl text-900 mb-4 font-bold">⚠️ Informativo</div>
            <p class="line-height-3 text-lg mb-4">
                <strong>Indicação de produto:</strong> deve ser selecionado quando o gestor precisa de um produto específico. Por exemplo: um produto de
                atendimento ao cliente, uma palestra de finanças, etc.
            </p>

            <p class="line-height-3 text-lg mb-4">
                <strong>Criação de cesta de produto:</strong> deve ser selecionado quando o gestor precisa criar uma cesta com vários produtos, que podem ou não
                ser do mesmo tema. Por exemplo: uma trilha digital de finanças para moda, uma cesta de marketing digital para o MEI, uma cesta de produtos para
                quem acabou de abrir um negócio, etc.
            </p>

            <p class="line-height-3 text-lg mb-4">
                <strong>Kahoot:</strong> O Kahoot é um jogo virtual (quiz) interativo de competição. Costuma ser usado em feiras, eventos, etc.
            </p>

            <Message :closable="false" class="w-full field col-12">
                Olá, <strong>{{ nomeSolicitante }}</strong
                >. Quando você enviar este formulário, o proprietário verá seu nome e endereço de email.
            </Message>
        </div>

        <div class="flex justify-content-start mt-4">
            <div class="flex align-items-start">
                <Checkbox v-model="confirmaLeitura" :binary="true" />
                <label for="ingredient1" class="ml-2 font-bold"> Confirmo que li os requisitos acima. </label>
            </div>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-times" class="p-button-text" @click="visualizarModal = false" outlined />
            <Button label="Próximo" icon="pi pi-check" @click="confirmaVisualizacao()" autofocus :disabled="!confirmaLeitura" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import chamadoService from './service';

export default {
    data() {
        return {
            visualizarAbaCuradoria: false,
            visualizarModal: false,
            confirmaLeitura: false,

            nomeGestor: '',
            unidade: '',
            necessidade: '',
            temas: '',
            publicoAlvo: '',
            instrumentoProduto: '',
            tipoPagamento: '',
            tipoProduto: '',
            descricaoObjetivo: '',
            descricaoObjetivoKahoot: '',
            informacaoAdicional: '',
            prazo: '',

            emailSolicitante: '',
            nomeSolicitante: '',

            necessidades: [
                { label: 'Indicação de produto', value: 'Indicação de produto' },
                { label: 'Criação de cesta de produto', value: 'Preciso da criação de uma cesta de produtos' },
                { label: 'Kahoot', value: 'Um game no Kahoot' },
            ],

            instrumentos: [
                { label: 'Presencial', value: 'Presencial' },
                { label: 'Online ao vivo', value: 'Online ao vivo' },
                { label: 'EAD / On Demand', value: 'EAD / On Demand' },
                { label: 'Não se aplica', value: 'Não se aplica' },
            ],

            tiposDePagamento: [
                { label: 'Gratuito', value: 'Gratuito' },
                { label: 'Pago', value: 'Pago' },
                { label: 'Ambos', value: 'Ambos' },
            ],

            tiposProdutos: [
                { label: 'Curso', value: 'Curso' },
                { label: 'Oficina', value: 'Oficina' },
                { label: 'Palestra', value: 'Palestra' },
                { label: 'Consultoria', value: 'Consultoria' },
                { label: 'Outros', value: 'Outros' },
            ],

            erros: [],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            nomeGestor: { required },
            unidade: { required },
            necessidade: { required },
            temas: { required },
            publicoAlvo: { required },
            instrumentoProduto: { required },
            tipoPagamento: { required },
            tipoProduto: { required },
            descricaoObjetivo: { required },
            prazo: { required },
            emailSolicitante: { required },
            nomeSolicitante: { required },
        };
    },
    methods: {
        async validarEnvio() {
            this.v$.$touch();
            if (this.v$.$invalid) return;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('formProdutoReal');
            this.enviar(token);
        },
        validarProximo() {
            this.v$.$touch();
            if (this.v$.nomeSolicitante.$invalid && this.v$.emailSolicitante.$invalid) return;
            this.abrirModal();
        },
        abrirModal() {
            this.visualizarModal = true;
        },
        confirmaVisualizacao() {
            this.v$.$reset();
            this.visualizarModal = false;
            this.visualizarAbaCuradoria = true;
        },
        enviar(token) {
            const solicitacao = {
                nomeGestor: this.nomeGestor,
                unidade: this.unidade,
                necessidade: this.necessidade.value,
                temas: this.temas,
                publicoAlvo: this.publicoAlvo,
                instrumentoProduto: this.instrumentoProduto.map((instrumento) => instrumento.value).join(', '),
                tipoPagamento: this.tipoPagamento.value,
                tipoProduto: this.tipoProduto.map((tipo) => tipo.value).join(', '),
                descricaoObjetivo: this.descricaoObjetivo,
                descricaoObjetivoKahoot: this.descricaoObjetivoKahoot,
                informacaoAdicional: this.informacaoAdicional,
                prazo: this.prazo,

                emailSolicitante: this.emailSolicitante,
                nomeSolicitante: this.nomeSolicitante,
                token: token,
            };

            this.$store.dispatch('addRequest');
            chamadoService
                .solicitarCuradoria(solicitacao)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Solicitação',
                        detail: 'Solicitação enviada com sucesso',
                        life: 3000,
                    });
                    this.$router.push({ name: 'sol_centralChamados' });
                })
                .catch((error) => {
                    error.response.data.errors.mensagens.forEach(e => {
                        this.erros.push(e)
                    });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
    watch: {
        confirmaLeitura() {
            this.confirmaLeitura ? (this.confirmaLeitura = true) : (this.confirmaLeitura = false);
        },
    },
};
</script>
